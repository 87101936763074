/* ============================================================================================= 
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Harlan
==============================================================================================*/

@import "sass-lib";

@media all and (max-width: 1279px) {

/* global
----------------------------------------------------------------------------------------------*/
.after_clear:after,.wrapper:after,section:after{clear:both;display: block;content:" "}
.before_clear:before{clear:both;display: block;content:" "}

.wrapper {
    width: 940px;
}
.container {width: 940px;}



/* header
----------------------------------------------------------------------------------------------*/
header {
    padding: 15px 0;
    .wrapper {
        margin: 0 30px;
    }
    .logo {margin-top: 6px;}
    .nav {
        display: none; position: fixed;top: 90px; left: 0; right: 0; background: #fff url('../images/material/bg_drop.jpg') no-repeat bottom center;padding: 0 30px;
        background-size: 100%; bottom: 0;
        ul {
            li {
                float: none; display: block; margin-right: 0; position: relative;
                a {display: block; border-bottom: 1px  solid #ddd; padding: 0 30px; font-weight: 500;}
                .arr_drop {
                    position: absolute; width: 80px; height: 50px; top: 0; right: 0px; display: block; cursor: pointer;
                    &:after {
                        width: 8px; height: 14px; content: ''; background: url('../images/material/arr_mn.png') no-repeat;
                        display: block; right: 0; top: 0; bottom: 0; margin: auto; position: absolute; left: 0; @include transition(all ease-out 0.25s);
                    }
                }
                ul {
                    display: none;
                    li {
                        a {padding-left: 60px;}
                        ul {
                            li {
                                a {padding-left: 90px;}
                            }
                        }
                    }
                }
                &.act {
                    > .arr_drop {
                        &:after {@include transform(rotate(90deg)); @include transition(all ease-out 0.25s);}
                    }
                    > a {color: $c_ckb;}
                }
            }
        }
        > ul {margin: 0 -30px; overflow: auto; padding-bottom: 20px;}
        .top_mobile {
            background: $c_ckb; margin: 0 -30px; padding: 25px 30px; @extend .after_clear; display: block;
            .src_mob {
                float:  left;
                form {
                    position: relative; display: block;
                    input[type="text"] {background: transparent; border: none; border-bottom: 2px solid #fff; padding: 8px 0 12px; font-size: 16px; @include boxSizing(border-box); width: 370px; color: #fff; padding-right: 30px;}
                    @include  placeholder {color: #fff;}
                    input[type="submit"] {
                        background: url('../images/material/ico_src_mob.png') no-repeat center; position: absolute; right: 0; top: 0; bottom: 0;margin: auto;
                        width: 30px; cursor: pointer; border: none;
                    }
                }
            }
            .lang_mob {
                float: right;
                a {
                    display: inline-block; line-height: 40px; color: #fff; font-size: 16px; position: relative; padding-right: 15px; margin-right: 13px;
                    border-right: 1px solid #166f50; font-weight: 500;
                    &:last-child {padding-right: 0; border: none; margin-right: 0;}
                    &.active,
                    &:hover {color: $c_yllow;}
                }
            }
        }
    }
    .lang,.src,.inquery {display: none;}
    .burger {
        width: 60px; height: 60px; background: $c_yllow;  display: inline-block; margin-top: -2px; position: relative;
        .tri_box {
            width: 100%; height: 100%; position: absolute;
            span {
                width: 6px; height: 6px; background: $c_ckb; position: absolute; margin: auto; display: inline-block;
                left: 0; right: 0;
                &:nth-child(1){
                    top: 15px; bottom: auto;
                }
                &:nth-child(2){
                    top: 0; bottom: 0;
                }
                &:nth-child(3){
                    bottom: 15px; top: auto;
                }
            }
        }
        .cross_box {
            width: 100%; height: 100%; position: absolute;
            span {
                width: 6px; height: 6px; background: $c_ckb; position: absolute; margin: auto; display: inline-block;
                left: 0; right: 0; top: 0; bottom: 0;
                &:nth-child(1){
                    @include transform(rotate(-45deg)); opacity: 0;
                }
                &:nth-child(2){
                    @include transform(rotate(45deg)); opacity: 0;
                }
            }
        }
    }
    &.float,
    &.home {padding: 15px 0;}
}


/* homepage
----------------------------------------------------------------------------------------------*/
.sliderHome {
	overflow: hidden;
	.slider {
		.caption {
            width: auto; 
            padding-left: 120px;
            margin: auto; 
        }
        .images {
            img {
                height: auto;
            }
        }
		// .images {
		// 	video {
		// 		height: calc(100vw * .64648);
		// 	}
		// }
        .slick-arrow {
            right: 20px;
            &.slick-prev {
                left: 20px;
            }

        }
	}
	.ckb-group-stay {
		.wrapper {
			padding: 24px 40px;
			h3 {
				font-size: 19px;
			}
			.link-icon {
				a {
					font-size: 14px;
					margin-right: 32px;
					padding-right: 32px;
					img {
						width: 24px;
					}
				}
			}
		}
	}
}

.nav_slideHome {
    left: 30px;
    top: 48%;@include transform(translateY(-48%));
    .line {max-width: 150px; left: -60px;}
}
.higlight {
    height: 105px;
    .container {width: 960px;}
    .title_ico {
        .ic_box {width: 135px;}
    }
    .left {padding: 18px 25px 15px 0; width: 57.5%;}
    .slideNews {margin-left: 200px;}
    .right {
        width: 42.5%;
        a {
            width: auto; line-height: 45px; padding: 0 15px;
            i {display: none;}
            &.booking {line-height: 41px;}
        }
        .drop_ship {top: 95px;}
    } 
}

.homeAbout {
   .txt_about {
        .rh_text {
            width: 480px;
            .lines {max-width: 130px; left: -70px;}
            .videos {
                .wrap_video {height: 268px;}
                #vidAbout {height: 268px;}
            }
        }
    }
    .img_about {
        img {min-height: 768px;}
    }
}
.businessHome {
   .ListBusiness {
        margin: 40px 0 0;
        .item {
            .capt {
                left: 20px; right: 20px;
                h5 {font-size: 15px; line-height: 20px; height: 40px;}
            }
        }
    }
}
.top_text {
   
}
.cus_say {
    .container {padding: 55px 0;}
    .wrapper {padding: 0 60px; width: auto;}
    .nav_slideCus {
        .slick-arrow {
            width: 40px; height: 40px;
            &.slick-prev {
                svg  {width: 40px!important; height: 40px!important;}
            }
        }
    }
    .slider_customer {
        .slide {
            .img-customer {
                figcaption {
                    .view-yt {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}


.homeNews {
   
}

.homeCareer {
    .txt_career {
        width: 400px; padding: 80px 0;
        .lines {max-width: 120px; left: -25px; margin: 20px 0 25px;}
    }
    .img_career {width: 63%;}

}

/* middle
----------------------------------------------------------------------------------------------*/

.middle {
    .wrapper {width: 940px;}
   
}
.banner {
    
}
.over_wrap {
   margin: 0 -55px 40px;
}
.title_content {
    
}
.nav_sub {
    width: 260px; margin-top: -50px;
    .sub_act {
        height: 50px; padding: 0 55px 0 20px;
        span {left: 20px;}
        i {
            height: 50px; width: 50px;
            &:after {width: 20px; height: 13px; background-size: 20px;}
        }
    }
    .drop_sub ul li a {padding: 18px 20px;}
}
.listAward {width: 940px;}
.wrap_text {
    p {}
}

.listing {
    .item {
        .ic_box {
            font-size: 16px; line-height: 22px; margin-bottom: 10px;
        }
        p {font-size: 13px;}

    }
    &.listService {
        .item {
            margin-bottom: 35px;
            .img {width: 180px;}
            .text {
                width: 255px;
                p {max-height: 74px;}
            }
            p {font-size: 13px; line-height: 18px; max-height: 54px;}
        }
    } 
    &.listIntegrated {
        .item {
            .ico {width: 65px; display: block; margin: 0 auto 20px;}
            h6 {font-size: 13px; line-height: 19px;}
        }
    }
    &.listIndustry {
        .item {
            .images {
                .txt_img {
                    img {width: 100px; margin-bottom: 20px;}
                    h6 {font-size: 19px;}
                }
            }
            .txt_hover {left: 25px; right: 25px;}
        }
    }
    &.listWho,
    &.listCr {
        .dec {
            h6 {font-size: 19px; line-height: 26px;}
        }
    }
}

.sliderContent {
    
}

.sec_bottom {
    padding-bottom: 90px; margin-bottom: -90px;
    .ic_box {font-size: 28px;}
    .slideRecommend {
        margin: 0 60px;
        .item {
            width: 250px; margin-right: 20px;
            .text {
                h6 {font-size: 16px;}
                p {font-size: 13px; line-height: 19px;}
            }
        }
        .slick-arrow {
            top: 105px;
            &.slick-prev {left: -70px;}
            &.slick-next {right: -70px;}
        }
    }
}


.about_sec {
    .text_about {
        .ic_box {
            margin-bottom: 25px;
            i {width: 120px;}
        }
        a.continue {margin-top: 20px;}
    }
    .img_compli {width: 57%;}
    .img_respons {
        width: 435px;
        .bg_green {width: 245px;}
    }
    &.who {
        .img_who {width: 41%; padding-top: 30px;}
        .text_about {
            padding-right: 0;
            .ic_box i {width: 120px;}
        }
    }
    &.compliance {
        min-height: 490px;
        .text_about {width: 435px; margin-top: 0;}
    }
    &.respons {
        min-height: 460px;
        .text_about {width: 505px; margin-top: 0; }
    }
}

.sliderGroup {
    margin: 40px 80px 0;
    .slide {
        margin-right: 20px; width: 245px;
        .dec {
            h6 {font-size: 19px;}
            p {font-size: 13px; line-height: 20px; height: 80px;}
        }
    }
}
.listVisi {
    h3 {font-size: 24px;}
}

.listFact {
    .item {
        p {line-height: 20px;}
    } 
}
.slideYear {
    .year {
        height: 130px;
        &.slick-center {
            h3 {font-size: 69px;}
        }
    }
}

.listAward {
    .list {
        width: auto;
        figure {
            width: 240px;
            img {width: 220px;}
        }
        .ket_award {margin-left: 300px;}
        &:nth-child(2n) {margin-left: 20%;}
    }
}

#map_logistic {height: 500px;}

.subCompliance {
    margin: 40px 0 0;
    .box {
        .ico {width: 150px;}
    }
}

.content_Compliance {
    width: auto;
    .box_white {padding: 70px 60px;}
}

.healthlist {
    .item {
        display: block; margin-bottom: 35px !important;
        .ico {float: none; display: table-cell; width: 90px; vertical-align: top;}
        .dec {margin-left: 0; display: table-cell; padding-left: 20px; margin-right: 0; position: relative; top: 0; @include transform(translateY(0%)); vertical-align: top;}
        p {line-height: 19px;}
    }
}

.slideCSR {
    .sliderCapt {
        .capt {
            h2 {font-size: 23px;}
        }
    }
}

.slideMangement {
    .slide {
        .txt {margin-left: 350px;  width: 470px;}
    }
}
.nav_slideMagement {
    .slick-arrow {
        width: 40px; height: 40px; margin: 30px 0;
        &.slick-prev {
            svg  {width: 40px!important; height: 40px!important;}
        }
    }
}
.sec_news {
    .img_news {
        position: absolute; left: 40px; overflow: hidden; width: auto; z-index: 1; top: 55px; @include transform(translateY(0));
        img {position: relative; left: -140px; max-width: 780px;}
    }
    .text_news {
        position: relative; z-index: 2;
        .newslisting {
            .list {
                p {font-size: 13px; line-height: 19px;}
            }
        }
    }
    &.indust {
        margin-bottom: -90px;
        .img_news {
            left: auto; right: 40px;
            img {left: auto; right: -80px;}
        }
    }
}

.gridNews {
    width: auto;
    .box {
        padding: 0 60px 40px;
        h3 {font-size: 23px; line-height: 36px; width: 72%;}
        .shortdec {
            padding-left: 150px;
            &:before {width: 85px;}
            &:after {width: 13px; height: 13px; left: 85px;}
            .categ {margin-bottom: 15px;}
        }
    }
}
.txt_filter {
    .right {
        width: 250px;
        .not_src {
            + .select2 {
                .select2-selection {height: 50px;}
                .select2-selection__rendered {padding: 10px 52px 11px 20px;}
                .select2-selection__arrow {
                    height: 49px;  width: 50px;
                    b{ width: 20px; height: 12px; background-size: 20px; }
                }
            }
        }
    }
}

.pg_career {
    padding: 120px 0 40px;
    .img_carrer {
        width: 650px;
    }
    .text_career {
        width: 410px;
        h3 {font-size: 33px; line-height: 40px;}
    }
}

#id_contactMap {height: 650px;}

.slideTestimoni {
    width: 940px;
    .box_img {width: 300px;}
    .box_testimoni {
        width: 640px;
        .isi {
            p {font-size: 16px; line-height: 26px;}
        }
        &:after {top: 35px; left: -35px;}
    }
}
#map_office {height: 600px;}

.btm_contact {
    .text_contact {width: 54%;}
    .lf_contact,
    .rh_contact {height: 320px;}
    .rh_contact {
        .text_contact {width: 55%;}
        .img {width: 230px;  right: -40px;}
    }
    .img {@include transform(scale(1)); width: 210px;  right: -10px;}
}
.nav_sub {
    &.office {
        margin-top: 0px;
        ul {
            li {
                a {
                    font-size: 12px; padding: 15px 10px 12px;
                    &:before {height: 5px;}
                }
            }
        }
    }
}
.listnetwork {
    .item {
        .keterangan {
            .loc {
                img {width: 80px;}
                span {margin-left: 100px; font-size: 16px; padding-left: 15px;}
            }
        }
    }
}
.btm_map {margin-bottom: -90px;}

.inquiry .txt_filter .right .not_src + .select2 .select2-selection {height: 49px;}


.full-section {
  figcaption {
    left: 50%;
    transform: translate(-50%, -50%); 
    margin-top: -35px; 
    .button-group {
      a {
        width: 170px;
        height: 43px;
        line-height: 43px; 
        background-size: 100% 100%;
        font-size: 13px; 
        margin: 0 14px;
      }
    }
  }
  figure {
    img {
      object-position: center center; 
    }
  }
}



/* footer
----------------------------------------------------------------------------------------------*/
footer {
    .top_footer {
        .right {width: 50%;}
        .main_foot {
            .col {
                float: none; width: 48%; display: inline-block; margin-right: 3%; vertical-align: top; margin-bottom: 40px;
                &:nth-of-type(2n) {margin-right: 0;}
                ul {
                    li {
                        a {font-size: 12px;}
                    }
                }
            }
        }
    }
    .btm_footer {
        .left {
        }
        .right {
            .list_foot {
                @include transform(scale(0.9)); position: relative;  right: -25px;
                a {display: inline-block;}
            }
        }
    }
}
footer .btm_footer {
    .left { height: auto !important; min-height: 75px;
        ul { font-size: 10px; 
            li a { font-size: 10px; }
        }
    }
}

.homeAbout {
	padding: 60px 0 30px;
	.videos {
		width: 460px;
		height: 302px;
		#vidAbout {
			height: 302px;
		}
		.wrap_video {
			height: 302px;
			.play_vid {
				width: 100px;
				height: 100px;
			}
		}
	}
	.txt_about {
			.rh_text {
				width: calc(100% - 460px);
				padding-left: 64px;
				box-sizing: border-box;
				p {
						font-size: 16px;
				}
			}
	}
}

.businessHome {
	padding-top: 30px;
	.top_text {
			width: 100%;
	}
	.list-services {
			width: 100%;
			padding-left: 0;
			padding-top: 20px;
	}
}

.home-global-player {
	padding: 72px 0 32px;
	.img-global {
		width: 420px;
	}
	.desc-global {
		width: calc(100% - 420px);
		padding-right: 72px;
		p {
			font-size: 16px;
		}
		.ls-global {
			&__item {
				padding: 0 8px;
				figure {
					img {
						max-width: 48px;
					}
				}
				.count {
					font-size: 32px;
					small {
						font-size: 14px;
					}
				}
			}
		}
	}
}
.cus_say {
	padding-bottom: 72px;
	h3 {
		font-size: 24px;
		margin-bottom: 32px;
	}
	.slider_customer {
		padding: 40px 120px 40px 48px;
		.slick-arrow {
			width: 48px;
			height: 48px;
			background-size: 100%;
			right: 40px;
			transform: translateY(calc(-50% + 20px));
			&.slick-prev {
				
			}
		}
	}
}


} /* end media */


@media  (min-width: 1023px) and (max-width: 1279px) {

.banner.thanks .txt_thanks.txt_404 {top: 40%;}
.banner.thanks .txt_thanks.txt_404 h1 {font-size: 120px;}

}

